<template>
  <div class="blog-details mx-1">
    <div v-if="postError">
      <no-item :text="postError"></no-item>
    </div>
    <div v-else>
      <v-row no-gutters>
        <v-col cols="12" sm="8">
          <div v-if="post">
            <post :snippeted="false" :post="post" />
          </div>
        </v-col>
        <v-col>
          <basic-card :loading="tagCloudLoading">
            <template #title>Navegue pelas tags</template>
            <tag-cloud
              class="d-flex justify-left justify-sm-space-between flex-wrap"
              :tags="tags"
            ></tag-cloud>
          </basic-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { Post, TagCloud } from '../components'
import { getPost, getTags } from '../composables'
import { NoItem } from '@/components/layout/error'
import { BasicCard } from '@/components/layout/cards'
export default {
  name: 'PostDetails',

  components: {
    Post,
    TagCloud,
    NoItem,
    BasicCard
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const { post, error: postError, load: loadPost } = getPost(props.id)
    const {
      tags,
      error: tagsError,
      load: loadTags,
      isPending: tagCloudLoading
    } = getTags()
    loadPost().then(() => {
      loadTags()
    })

    return { post, tags, postError, tagsError, tagCloudLoading }
  }
}
</script>

<style></style>
