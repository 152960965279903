import { ref, computed } from '@vue/composition-api'
import store from '@/store'

const getTasks = () => {
  const error = ref(null)
  const tasks = computed(() => {
    return store.state.tasks.tasks
  })
  const doneTasks = computed(() => {
    return store.getters['tasks/doneTasks']
  })
  const undoneTasks = computed(() => {
    return store.getters['tasks/undoneTasks']
  })
  const doneTasksPercent = computed(() => {
    return store.getters['tasks/doneTasksPercent']
  })

  return { error, tasks, undoneTasks, doneTasks, doneTasksPercent }
}

export default getTasks
