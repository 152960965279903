<template>
  <div>
    <h1>Create a new Post</h1>
    <post-form></post-form>
  </div>
</template>

<script>
import { PostForm } from '../components'

export default {
  name: 'CreatePost',

  components: {
    PostForm
  }
}
</script>
