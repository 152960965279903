<template>
  <div>
    <app-btn-fab
      v-if="showFAB"
      color="primary"
      icon="plus"
      @click="openAddTaskSheet"
    ></app-btn-fab>
    <app-btn v-else block lIcon="plus" color="success" @click="openAddTaskSheet"
      >Adicionar Tarefa</app-btn
    >
    <bottom-sheet size="250" :dialog="addTaskSheet" @close="closeAddTaskSheet">
      <v-form @submit.prevent="handleSubmit">
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <app-text-field
              v-model="title"
              :disabled="isPending"
              dense
              placeholder="Título"
            ></app-text-field>
          </v-col>
          <v-col>
            <app-text-field
              v-model="date"
              :disabled="isPending"
              dense
              placeholder="Data"
              type="date"
            ></app-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <app-text-field
              v-model="description"
              color="default"
              :disabled="isPending"
              dense
              placeholder="Descrição"
            ></app-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3">
          <app-btn
            block
            color="default"
            rIcon="calendar-plus"
            :loading="isPending"
            :disabled="formError"
            type="submit"
            >Adicionar</app-btn
          >
        </v-row>
      </v-form>
    </bottom-sheet>
  </div>
</template>

<script>
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import utils from '@/utils/utils'
export default {
  name: 'TaskForm',

  components: {
    BottomSheet
  },

  props: {
    showFAB: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    // const sheet = ref(false)
    const isPending = ref(false)
    const title = ref('')
    const date = ref('')
    const description = ref('')

    const {
      dialog: addTaskSheet,
      handleOpen: openAddTaskSheet,
      handleClose: closeAddTaskSheet
    } = useDialogs()

    const handleSubmit = async () => {
      isPending.value = true
      const task = {
        id: utils.uuid4(),
        isDone: false,
        title: title.value,
        description: description.value,
        date: date.value
      }
      await utils.delay(1000)
      store.dispatch('tasks/addTask', task)
      title.value = ''
      description.value = ''
      date.value = ''
      isPending.value = false
      closeAddTaskSheet()
    }

    const formError = computed(() => {
      return title.value === '' || date.value === ''
    })

    return {
      addTaskSheet,
      isPending,
      title,
      date,
      description,
      formError,
      handleSubmit,
      openAddTaskSheet,
      closeAddTaskSheet
    }
  }
}
</script>

<style></style>
