<template>
  <div>
    <basic-card>
      <template #title>
        <router-link :to="{ name: 'PostDetails', params: { id: post.id } }">
          <div class="text-title">{{ post.title }}</div>
        </router-link>
      </template>

      <template #footer
        >Tags:
        <tag-cloud :tags="post.tags"></tag-cloud>
      </template>

      <template #info>
        <span class="text-caption text-left"> <a href="#">by betogroo</a></span>
      </template>

      <p>
        {{ snippet }}
        <app-btn
          v-if="snippeted"
          :to="{ name: 'PostDetails', params: { id: post.id } }"
          icon
        >
          <app-icon color="primary" icon="chevron-down"></app-icon>
        </app-btn>
      </p>
    </basic-card>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { BasicCard } from '@/components/layout/cards'

export default {
  name: 'Post',

  components: {
    BasicCard,
    TagCloud: () => import('../components/TagCloud')
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    snippeted: {
      type: Boolean,
      default: true
    }
  },

  setup(props) {
    const snippet = computed(() => {
      const body = props.post.body
      if (props.snippeted) {
        return body.length > 200 ? body.substring(0, 200) + '...' : body
      }
      return body
    })

    return { snippet }
  }
}
</script>

<style>
.v-application a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.v-application a:hover {
  text-decoration: none;
  color: blue;
}
</style>
