<template>
  <div class="post-list">
    <div v-for="post in posts" :key="post.id">
      <post :post="post" />
    </div>
  </div>
</template>

<script>
import { Post } from './'
export default {
  name: 'PostList',

  components: {
    Post
  },
  props: {
    posts: {
      type: Array,
      required: true
    }
  }
}
</script>

<style></style>
