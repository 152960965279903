import { ref } from '@vue/composition-api'
import Blog from '@/services/Blog'

const getTags = () => {
  const tags = ref([])
  const tagSet = new Set()
  const error = ref(null)
  const isPending = ref(false)

  const load = async () => {
    isPending.value = true

    try {
      const posts = await Blog.getPosts('posts')
      posts.data.forEach((item) => {
        item.tags.forEach((tag) => tagSet.add(tag.toLowerCase()))
      })
      const data = tagSet
      tags.value = [...data].sort()
      console.log('getTags', data)
      isPending.value = false
    } catch (err) {
      isPending.value = false
      error.value = err.data
      console.log(error.value)
    }
  }

  return { tags, error, load, isPending }
}

export default getTags
