<template>
  <v-app-bar app height="48px" bottom class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-form class="pa-0 ma-0">
          <app-text-field
            v-model="message"
            rounded
            height="24"
            placeholder="Digite sua mensagem"
            @keypress.enter.prevent="handleSubmit"
          >
            <template #append-outer>
              <v-slide-x-transition>
                <app-icon
                  v-if="message"
                  class="pa-0 ma-0"
                  icon="send"
                  color="default"
                  @click.prevent="handleSubmit"
                ></app-icon>
              </v-slide-x-transition>
            </template>
          </app-text-field>
        </v-form>
        <div v-if="error">{{ error }}</div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { ref } from '@vue/composition-api'
import { fbTimestamp } from '@/plugins/firebase'
import { useCollection } from '@/composables'
export default {
  name: 'NewChatForm',

  props: {
    loggedUser: {
      type: [Object, undefined],
      required: true
    }
  },

  setup(props) {
    const { addDoc, error } = useCollection('message')
    const message = ref('')

    const handleSubmit = async () => {
      if (message.value === '') {
        console.log('Nada')
        return false
      }
      const chat = {
        uid: props.loggedUser.uid,
        name: props.loggedUser.displayName,
        message: message.value,
        createdAt: fbTimestamp()
      }

      await addDoc(chat)
      if (!error.value) {
        message.value = ''
      }
    }

    return { message, handleSubmit, error }
  }
}
</script>
