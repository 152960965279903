<template>
  <v-list subheader two-line>
    <v-subheader v-if="items.length" class="text-h6">{{ title }}</v-subheader>
    <v-divider />
    <template v-for="(item, i) in items">
      <v-list-item :key="`task_${item.id}`">
        <v-list-item-content>
          <v-list-item-title
            :class="item.isDone ? text.done : text.undone"
            v-text="item.title"
          ></v-list-item-title>

          <v-list-item-subtitle
            :class="item.isDone ? text.done : text.undone"
            v-text="item.description"
          ></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text
            :class="item.isDone ? text.done : text.undone"
            >06/08/2021</v-list-item-action-text
          >
          <app-btn icon :loading="isPending && i === index">
            <app-icon
              :color="item.isDone ? 'grey' : 'black'"
              :icon="
                !item.isDone
                  ? 'checkbox-blank-outline'
                  : 'checkbox-marked-outline'
              "
              @click="toggleTask(item)"
            ></app-icon>
          </app-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider v-if="i < items.length - 1" :key="i"></v-divider>
    </template>
  </v-list>
</template>

<script>
import { ref } from '@vue/composition-api'
import utils from '@/utils/utils'
import store from '@/store'
export default {
  name: 'Tasklist',

  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    }
  },

  setup(props) {
    const index = ref(-1)
    const isPending = ref(false)
    const text = ref({
      done: 'text-decoration-line-through text--disabled',
      undone: ''
    })

    const toggleTask = async (item) => {
      index.value = utils.getIndex(props.items, item.id)
      isPending.value = true
      await utils.delay(1000)
      store.dispatch('tasks/toggleTask', item)
      isPending.value = false
      index.value = -1
    }

    return { index, isPending, text, toggleTask }
  }
}
</script>
