<template>
  <h1>Messages</h1>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Messages',

  computed: {
    ...mapState({
      messages: (state) => state.messages.messages
    })
  }
}
</script>

<style></style>
