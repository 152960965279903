<template>
  <div class="home">
    <div v-if="postsError">
      <no-item></no-item>
    </div>

    <div v-else>
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="text-sm-center">
            {{ ` Posts com a tag #${currentTag}` }}
          </h1>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="8">
          <div v-if="posts.length">
            <post-list :posts="postsWithTag" />
          </div>
        </v-col>
        <v-col>
          <basic-card :loading="tagCloudLoading">
            <template #title>Navegue pelas tags</template>
            <tag-cloud
              class="d-flex justify-left justify-sm-space-between flex-wrap"
              :tags="tags"
            ></tag-cloud>
          </basic-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { PostList, TagCloud } from '../components'
import { getPosts, getTags } from '../composables'
import { NoItem } from '@/components/layout/error'
import { BasicCard } from '@/components/layout/cards'
import utils from '@/utils/utils'

export default {
  name: 'Tags',

  components: { PostList, TagCloud, NoItem, BasicCard },

  setup() {
    const route = utils.useRoute()
    const currentTag = computed(() => {
      return route.params.tag
    })

    const { posts, error: postsError, load: loadPosts } = getPosts()
    const {
      tags,
      error: tagsError,
      load: loadTags,
      isPending: tagCloudLoading
    } = getTags()
    loadPosts()
    loadTags()
    const postsWithTag = computed(() => {
      return posts.value.filter((post) => post.tags.includes(currentTag.value))
    })

    return {
      postsError,
      tagsError,
      tags,
      posts,
      currentTag,
      postsWithTag,
      tagCloudLoading
    }
  }
}
</script>

<style></style>
