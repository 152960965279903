<template>
  <basic-card>
    <template #title>Tasks</template>
    <template #subtitle>{{
      `${tasks.length} tarefas (${doneTasks.length} concluídas)`
    }}</template>
    <template #info>
      <v-progress-circular
        :rotate="360"
        :size="80"
        :width="12"
        :value="doneTasksPercent"
        color="teal"
      >
        {{ `${doneTasksPercent}%` }}
      </v-progress-circular>
    </template>
    <v-row no-gutters>
      <v-col cols="12">
        <task-form />
      </v-col>
      <v-col cols="12" sm="6">
        <tasklist :items="undoneTasks" title="A Fazer" />
      </v-col>
      <v-col cols="12" sm="6">
        <tasklist :items="doneTasks" title="Concluídas" />
      </v-col>
    </v-row>
  </basic-card>
</template>

<script>
import { getTasks } from '../composables'
import { Tasklist, TaskForm } from '../components'
import { BasicCard } from '@/components/layout/cards'

export default {
  name: 'Tasks',

  components: {
    Tasklist,
    TaskForm,
    BasicCard
  },

  setup() {
    const { error, tasks, undoneTasks, doneTasks, doneTasksPercent } =
      getTasks()

    return {
      error,
      tasks,
      doneTasksPercent,
      doneTasks,
      undoneTasks
    }
  }
}
</script>
