<template>
  <div>
    <div>
      <h3 v-if="loggedUser">
        Seja vindo {{ loggedUser.displayName || loggedUser.email }}
      </h3>
      <chat-window :loggedUser="loggedUser"></chat-window>
    </div>
    <new-chat-form :loggedUser="loggedUser" />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { NewChatForm, ChatWindow } from '../components'
import store from '@/store'
export default {
  name: 'Chatroom',

  components: {
    NewChatForm,
    ChatWindow
  },

  setup() {
    const loggedUser = computed(() => {
      return store.state.auth.loggedUser
    })

    return { loggedUser }
  }
}
</script>
