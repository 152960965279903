<template>
  <div ref="messages" class="messages">
    <div v-if="error">{{ error }}</div>
    <div v-if="documents && documents.length > 0">
      <app-card
        v-for="doc in serializedDocuments"
        :key="doc.id"
        outlined
        flat
        tile
        elevation="6"
        color="light-blue"
        max-width="70vw"
        class="mt-1 py-1 px-2"
        :class="
          doc.uid === loggedUser.uid
            ? 'ml-auto rounded-l-xl lighten-4'
            : ' mr-auto rounded-r-xl lighten-5'
        "
      >
        <div class="d-flex align-center justify-space-between">
          <div class="text-overline text-no-wrap">
            {{ doc.name || doc.email }}
          </div>
          <div class="text-caption text-no-wrap">
            {{ doc.createdAt }}
          </div>
        </div>

        <div class="text-body-1 text-justify">
          {{ doc.message }}
        </div>
      </app-card>
    </div>
    <div v-else>Chat ainda não iniciado. Digite no campo abaixo.</div>
  </div>
</template>

<script>
import { computed, onUpdated, ref } from '@vue/composition-api'
import utils from '@/utils/utils'
import { getCollection } from '@/composables'
export default {
  name: 'ChatWindow',

  props: {
    loggedUser: {
      type: [Object, undefined],
      required: true
    }
  },

  setup() {
    const { error, documents } = getCollection('message')
    const serializedDocuments = computed(() => {
      if (documents.value) {
        return documents.value.map((doc) => {
          let time = utils.distanceDateStrict(doc.createdAt)
          return { ...doc, createdAt: time, color: 'primary' }
        })
      }
    })

    // auto scroll
    const messages = ref(null)

    onUpdated(() => {
      messages.value.scrollTop = messages.value.scrollHeight
    })

    return { error, documents, serializedDocuments, messages }
  }
}
</script>

<style scoped>
.messages {
  max-height: 90vh;
  overflow: auto;
}
</style>
