<template>
  <div>
    <app-chip
      v-for="tag in tags"
      :key="tag"
      class="my-1"
      :title="tag"
      hashtag="#"
      small
      :to="{ name: 'Tags', params: { tag: tag } }"
    ></app-chip>
  </div>
</template>

<script>
export default {
  name: 'TagCloud',

  props: {
    tags: {
      type: [Array],
      required: true
    }
  }
}
</script>
