import { ref } from '@vue/composition-api'
import store from '@/store'
import Blog from '@/services/Blog'

const captureError = (error) => {
  switch (error) {
    case 404:
      return `${error} - Página não encontrada`

    default:
      break
  }
}

const getPost = (id) => {
  const post = ref(null)
  const error = ref(null)

  const load = async () => {
    store.dispatch('home/setOverlayLoading', true)
    try {
      const res = await Blog.getPost(id)
      console.log('getPost', id, res)
      post.value = res.data
      error.value = null
      store.dispatch('home/setOverlayLoading', false)
    } catch (err) {
      error.value = captureError(err.response.status)
      store.dispatch('home/setOverlayLoading', false)
      console.log(error.value)
    }
  }
  return { post, error, load }
}

export default getPost
