<template>
  <app-form @submit.prevent="addPost">
    <app-text-field v-model="title" label="Título"></app-text-field>
    <app-text-field v-model="body" label="Mensagem"></app-text-field>
    <v-combobox
      v-model="tags"
      :items="knowTags"
      class="ma-1"
      :search-input.sync="searchTag"
      hide-selected
      solo
      label="Selecione ou digite uma tag"
      multiple
      small-chips
    >
    </v-combobox>
    <app-btn block type="submit">Postar</app-btn>
  </app-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import utils from '@/utils/utils'
import router from '@/router'
import { getTags } from '../composables'
export default {
  name: 'PostForm',

  setup() {
    const title = ref('')
    const body = ref('')
    const tags = ref([])
    const searchTag = ref(null)
    const { tags: knowTags, load: loadTags } = getTags()
    loadTags()
    // const knowTags = ref(['Gaming', 'Programming', 'Vue', 'Vuetify'])

    const addPost = async () => {
      store.dispatch('home/setOverlayLoading', true)
      await utils.delay()
      const data = {
        id: utils.uuid4(),
        title: title.value,
        body: body.value,
        tags: tags.value
      }

      try {
        const post = await fetch('http://localhost:3000/posts', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
        if (!post.ok) {
          store.dispatch('home/setOverlayLoading', false)
          throw Error('Erro ao tentar gravar os dados')
        }

        store.dispatch('home/setOverlayLoading', false)
        console.log(post)
        router.push({ name: 'PostDetails', params: { id: data.id } })
      } catch (err) {
        store.dispatch('home/setOverlayLoading', false)
        console.log(err.message)
      }
    }

    return { title, body, tags, knowTags, searchTag, addPost }
  }
}
</script>

<style></style>
