import http from '@/services'

export default {
  getPost: (id) => {
    return http.get(`posts/${id}`)
  },
  getPosts: () => {
    return http.get('posts')
  }
}
