<template>
  <div>
    <h1>Home</h1>
    29/09/2021-0811
    <app-speed-dial large>
      <app-btn small fab>
        <app-icon icon="account"></app-icon>
      </app-btn>
    </app-speed-dial>
  </div>
</template>

<script>
export default {
  name: 'Home',

  setup() {}
}
</script>
