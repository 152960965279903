<template>
  <div class="home">
    <div v-if="postsError">
      <no-item :text="postsError"></no-item>
    </div>

    <div v-else>
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="text-sm-center">Home - Blog</h1>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="8">
          <div v-if="posts.length">
            <post-list :posts="posts" />
          </div>
        </v-col>
        <v-col>
          <basic-card :loading="tagCloudLoading">
            <template #title>Navegue pelas tags</template>
            <tag-cloud
              class="
                d-flex
                justify-space-around justify-sm-space-between
                flex-wrap
              "
              :tags="tags"
            ></tag-cloud>
          </basic-card>
        </v-col>
      </v-row>
      <app-btn-fab icon="plus" @click="openPostSheet"></app-btn-fab>
      <bottom-sheet :dialog="postSheet" @close="closePostSheet">
        <post-form></post-form>
      </bottom-sheet>
    </div>
  </div>
</template>

<script>
import { getPosts, getTags } from '../composables'
import { PostList, PostForm, TagCloud } from '../components'
import { BottomSheet, useDialogs } from '@/components/layout/dialogs'
import { BasicCard } from '@/components/layout/cards'
import { NoItem } from '@/components/layout/error'
export default {
  name: 'HomeBlog',

  components: { PostList, TagCloud, BottomSheet, PostForm, NoItem, BasicCard },

  setup() {
    const { posts, error: postsError, load: loadPosts } = getPosts()
    const {
      tags,
      error: tagsError,
      load: loadTags,
      isPending: tagCloudLoading
    } = getTags()

    const {
      dialog: postSheet,
      handleClose: closePostSheet,
      handleOpen: openPostSheet
    } = useDialogs()
    loadPosts().then(() => {
      loadTags()
    })
    return {
      posts,
      tags,
      postsError,
      tagsError,
      postSheet,
      closePostSheet,
      openPostSheet,
      tagCloudLoading
    }
  }
}
</script>
